<script src="../../../api/risk.js"></script>
<template>
    <div class="container">
        <div class="swiperBox">
            <div class="progressLabel">
                <div class="ct">
                    <text class="cur">{{current+1}}</text>
                    <text>/</text>
                    <text class="tot">{{total}}</text>
                </div>
            </div>
            <div class="swiper" :current="current" :duration="300" :disable-touch="true">
                <div v-for="(item,index) in saleSubjectInfo" :key="item.subjectContent">
                    <div class="swiper-item">
                        <div class="title">{{index+1}}.{{item.subjectContent}}</div>
                        <radio-group @change="radioChange">
                            <label class="answerItem" v-for="(item1,index1) in item.answerList" :key="item1.answerContent + index1">
                                <div class="answer">{{item1.answerContent}}</div>

                                <input v-model="radioVal" :value="item1.answerOption" color="#D8B691" type="radio"  @change="getRadioVal">
                            </label>
                        </radio-group>
                    </div>
                </div>
            </div>
            <div class="optBox">
                <div class="nextBox">
                    <div v-show="current ==0"></div>
                    <div class="prev" @click="prev" v-show="current !=0">
                        <text class="iconfont icon-check"></text>
                        上一题
                    </div>
                    <div class="next" @click="next" v-show="current != total-1">
                        <text class="iconfont icon-check"></text>
                        下一题
                    </div>
                    <div v-show="current == total-1"></div>
                </div>
                <div class="progress" :style="{'width':progress}"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { available,rating } from '../../../api/risk.js'
    export default {
        data(){
            return {
                backStatus:true,
                current: 0,
                total:0,
                data:{},
                saleSubjectInfo:[],
                fd:{
                    strategyId:'',
                    clientAnswerInfoDTOList:[],
                },
                riskLevelArr:[],
            }
        },
        mounted() {
            available({}).then(r=>{
                console.log(r.data)
                this.data = r.data;
                this.fd.strategyId = r.data.strategyId;
                this.saleSubjectInfo = r.data.saleSubjectInfo;
                this.total = r.data.saleSubjectInfo.length;
            });
            this.getDicts('hs_risk_Level').then(r1=>{
                this.riskLevelArr = r1.data;
            });
        },
        computed:{
            progress(){
                let p = (this.current + 1)/this.total * 100;
                return `${p}%`;
            }
        },
        methods:{
            radioChange({detail}){
                let item = {
                    subjectId:this.saleSubjectInfo[this.current].subjectId,
                    answerOption:detail.value,
                };
                this.fd.clientAnswerInfoDTOList[this.current] = item;
                if(this.current == this.total-1){
                    rating(this.fd).then(r=>{
                        let riskLevel = this.riskLevelArr.filter(v=>v.dictValue==r.data.riskLevel)[0].dictLabel
                        if(r.code===200){
                            this.$router.push({
                                url:`./result?level=${riskLevel}&lastTestDate=${r.data.lastTestDate}&fitProdForClient=${r.data.fitProdForClient}`
                            })
                        }
                    })
                }else{
                    this.current ++;
                }
            },
            prev(){
                this.current --;
            },
            next(){
                let flag = this.fd.clientAnswerInfoDTOList[this.current]
                if(!flag){
                    alert({
                        title:'请选择答案',
                        icon:'none'
                    })
                }else{
                    this.current ++;
                }
            },
            backStop(val){
                alert({
                    title:'温馨提示',
                    content:'您未完成测评,是否放弃？',
                    cancelText:'放弃',
                    confirmText:'继续测评',
                    success:(e)=>{
                        if(e.confirm){
                            this.backStatus = true;
                            console.log('确定')
                        }else{
                            this.backStatus = false;
                        }
                    },
                })
            }
        },
        onBackPress(options){
            if (options.from === 'navigateBack') {
                return false;
            };
            alert({
                title:'温馨提示',
                content:'您未完成测评,是否放弃？',
                cancelText:'放弃',
                confirmText:'继续测评',
                success:(e)=>{
                    if(e.confirm){
                        this.backStatus = true;
                        console.log('继续测评')
                    }else{
                        console.log('放弃')
                        this.backStatus = false;
                    }
                },
            });
            return true;
        }
    }
</script>

<style scoped>
    .container{
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #F8F7F7;
    }
    .swiperBox{
        margin-top: 15px;
        flex: 1;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
    }
    .progressLabel{
        /* color: #FFFAF5; */
        color: #000000;
        font-size: 20px;
        position: absolute;
        right: 0;
        top: 0;
        width: 90px;
        height: 90px;
        background-image: url(../../../static/img/my/riskTagBg.png);
        background-size: 100% 100%;
    }
    .ct{
        margin-top: 30px;
        display: flex;
        align-items: center;
        padding: 4px 0 0 12px;
        transform: rotate(-26deg);
        color: #fff;
    }
    .cur{
        font-size: 26px;
        font-weight: bold;
        transform: rotate(24deg);
    }
    .tot{
        transform: rotate(24deg);
    }
    .swiper {
        flex: 1;
        overflow-y: auto;
    }
    .swiper-item {
        padding: 0 40px;
        height: 100%;
        overflow: auto;
    }
    .title{
        color: #000000;
        font-size: 36px;
        padding: 68px 0 48px;
    }
    .answerItem{
        margin-bottom: 30px;
        padding: 48px 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
    }
    .answer{
        font-size: 28px;
        padding: 0 40px 0 0;
        flex: 1;
        word-break: break-word;
    }
    .radio{
        width: 42px;
        height: 42px;
    }
    .optBox{
        padding-top: 31px;
        height: 132px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .nextBox{
        padding: 0 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .prev,.next{
        margin: 0;
        padding: 0;
        font-size: 24px;
        text-align: center;
        width: 210px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
    }
    .prev{
        color: #D4A77E;
        border: 1px solid #D8B691;
    }
    .next{
        color: #8691A8;
        border: 1px solid #BDC0CB;
    }
    .progress{
        width: 0;
        height: 4px;
        background: linear-gradient(270deg, #E45247 0%, #FFA59E 100%);
    }
    </style>